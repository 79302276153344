import { normalizeContentfulAssetUrl } from "./normalizeContentfulAssetUrl";
import { normalizeFormConfigName } from "./normalizeFormConfigName";

export function serializeContentfulBlocks(contentfulBlocks) {
    let referencesMap = new Map();

    contentfulBlocks.references?.forEach(reference => {
        referencesMap.set(reference.contentful_id, reference);
    })

    function nodeToBlock(node) {
        let block = {};

        if (node.nodeType === 'embedded-entry-block') {
            const nodeContentfulId = node.data.target.sys.id;

            if (!referencesMap.has(nodeContentfulId)) return {};

            const reference = referencesMap.get(nodeContentfulId);

            const referenceType = reference?.internal?.type;

            if (referenceType === 'ContentfulCtaButton') {
                return {
                    type: referenceType,
                    url: reference.url,
                    children: [
                        {
                            text: reference.label
                        }
                    ]
                }
            }

            if (reference.assetFromBrandfolder) {
                block.type = "embedded-asset-block"
                block.url = reference.assetFromBrandfolder[0].url;
                return block
            }

            if (referenceType === 'ContentfulForm') {
                const form = {
                    type: "AccForm",
                    data: {
                        style: { color: "blue" },
                        containerStyle: { paddingBottom: "45px" },
                        alignment: "center",
                        type: 'resourceForm',
                        thankyou: [
                            {
                                type: "Body",
                                textStyle: { textAlign: "center" },
                                children: [
                                    {
                                        text: "Thank you!"
                                    }
                                ]
                            }
                        ]
                    },
                    blocks: []
                }

                if (reference.RewardAsset) {
                    form.data.onSubmitSuccess = `() => window.open('${reference.RewardAsset[0]?.url}', '_blank')`;
                } else if (reference.rewardAsset?.file?.url) {
                    form.data.onSubmitSuccess = `() => window.open('${normalizeContentfulAssetUrl(reference.rewardAsset.file.url)}', '_blank')`;
                }

                return form;
            }

            return {};
        }

        if (node.nodeType === "text") {
            block.text = node.value;

            node.marks?.forEach(mark => {
                block[mark.type] = true;
            })

            return block;
        }

        block.type = node.nodeType;

        if (node.nodeType === "embedded-asset-block") {
            const nodeContentfulId = node.data.target.sys.id;

            const fileReference = referencesMap.get(nodeContentfulId);

            block.url = normalizeContentfulAssetUrl(fileReference.file.url);

            block.alt = fileReference.title;
        }

        if (node.nodeType === "hyperlink") {
            block.url = node.data.uri;
        }

        if (node.content?.length) {
            block.children = node.content.map(nodeToBlock);
        }

        return block;
    }

    const nodes = JSON.parse(contentfulBlocks.raw).content;

    return {
        blocks: [
            {
                component: "RichText",
                data: {
                    content: nodes.map(nodeToBlock)
                }
            }
        ]
    };
}
