import { useState, useEffect } from 'react'

const useScroll = () => {
  // setting initial value to true
  const [scroll, setScroll] = useState(1)
  const windowGlobal = typeof window !== 'undefined' && window

  // running on mount
  useEffect(() => {
    const onScroll = () => {
      const scrollAtTop = windowGlobal.scrollY === 0
      if (scrollAtTop !== scroll) {
        setScroll(scrollAtTop)
      }
    }

    // setting the event handler from web API
    document.addEventListener('scroll', onScroll)

    // cleaning up from the web API
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [scroll, setScroll])

  return scroll
}

export default useScroll
