import React from 'react'
import styled from "@emotion/styled";
import { mq } from "../../hooks";

import Phase1Image from '../../../images/vaccine/Phase1.png'
import Phase2Image from '../../../images/vaccine/Phase2.png';
import Phase3Image from '../../../images/vaccine/Phase3.png';

import smileyIcon from '../../../images/vaccine/smiley.svg';
import lifeRingIcon from '../../../images/vaccine/life-ring.svg';
import flaskIcon from '../../../images/vaccine/flask.svg';
import lungsIcon from '../../../images/vaccine/lungs.svg';
import magicWandIcon from '../../../images/vaccine/magic-wand.svg';
import manHourIcon from '../../../images/vaccine/man-hour.svg';

const Container = styled.div`
${mq({
    display: 'flex',
    justifyContent: 'center',
    alignItems: ['center','center', 'flex-start'],
    flexDirection: ['column', 'column', 'row'],
    fontSize: ['14px', '14px', '16px']
})}
`

const PhaseCard = styled.div`
${mq({
    maxWidth: '379px',
    padding: '1.5rem 3rem',
    background: 'white',
    borderRadius: '1rem',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  
    // mobile margin
    marginTop: ['15px', '15px', 0],
    marginBottom: ['15px', '15px', 0],

  // desktop margins
    marginLeft: ['30px', '30px', '2rem'],
    marginRight: ['30px', '30px', 0 ],
    '&:last-child': {
        marginRight: ['30px', '30px', '2rem'],
    },
})}
`

const PhaseCardHeading = styled.div`
  font-style: normal;
  
  font-weight: 800;
  font-size: 2.5em;
  line-height: 1.5;

  color: #1079BC;
  
  text-align: center;
  
  margin-bottom: 2rem;
`

const PhaseCardBody = styled.div`
    margin-bottom: 1.5rem;
`

const PhaseCardImage = styled.img`
    margin-bottom: 1.5rem;
`

const PhaseCardBullet = styled.div`
    align-self: flex-start;
  
    display: flex;
    margin-bottom: 1.5rem;
`

const PhaseCardBulletIcon = styled.img`
    margin-right: 1.5rem;
    height: 1.5rem;
    width: 1.5rem;
`

const PhaseCardBulletText = styled.span``

const CARDS = [
    {
        heading: "Phase 1",
        image: Phase1Image,
        body: "Clinical trials with up to 100 healthy volunteers determine if this vaccine's:",
        bulletPoints: [
            {
                icon: lifeRingIcon,
                text: 'safety'
            },
            {
                icon: flaskIcon,
                text: 'correct dose'
            },
            {
                icon: smileyIcon,
                text: 'most common side effects'
            },
        ]
    },
    {
        heading: "Phase 2",
        image: Phase2Image,
        body: "Clinical trials with several hundred volunteers determine:",
        bulletPoints: [
            {
                icon: smileyIcon,
                text: 'the most common short-term side effects'
            },
            {
                icon: lungsIcon,
                text: "volunteers' immune\nsystem response",
            },
        ]
    },
    {
        heading: "Phase 3",
        image: Phase3Image,
        body: "Clinical trials with hundreds or thousands of volunteers to determine this vaccine's:",
        bulletPoints: [
            {
                icon: lifeRingIcon,
                text: 'safety'
            },
            {
                icon: magicWandIcon,
                text: "effectiveness",
            },
            {
                icon: smileyIcon,
                text: "most common side effects",
            },
            {
                icon: manHourIcon,
                text: "response in people who get the vaccine compared to people who do not",
            },
        ]
    },
]

export default function VaccineDevelopmentPhases() {
    return (
        <Container>
            {CARDS.map(card => <PhaseCard>
                <PhaseCardHeading>{card.heading}</PhaseCardHeading>
                <PhaseCardImage src={card.image} alt={`{card.heading} image`} />

                <PhaseCardBody>{card.body}</PhaseCardBody>

                {card.bulletPoints.map(bulletPoint => (
                    <PhaseCardBullet>
                        <PhaseCardBulletIcon src={bulletPoint.icon} alt=""/>
                        <PhaseCardBulletText>{bulletPoint.text}</PhaseCardBulletText>
                    </PhaseCardBullet>
                ))}
            </PhaseCard>)}
        </Container>
    )
}