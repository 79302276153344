/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import { mq, useDataTransform } from '../../hooks'
import { useTheme } from 'emotion-theming'
import { v4 as uuidv4 } from 'uuid'
import { Resources } from '../Resources'

// ////////////////////////////////////////////////////////////////////////////
// All Components
// ////////////////////////////////////////////////////////////////////////////
import { AccForm, FormRouter, AccDemoForm } from '../../forms'
import {
  Accordion,
  Accordions,
  AONCampaignTabs,
  AnimatedGIF,
  CallToAction,
  CallToActions,
  Card,
  Grid,
  Hero,
  Image,
  ImageOverlay,
  Indexer,
  Leader,
  List,
  ListItem,
  Metric,
  NavTile,
  QuoteGrid,
  RaisedBox,
  RichText,
  Section,
  SideBySide,
  Spacer,
  Speaker,
  Speakers,
  Video,
} from '../../components'
import { BlogHeader, PressNewsHeader, EventHeader } from '../../components'
import styled from '@emotion/styled'
import { serializeContentfulBlocks } from "../../lib/serializeContentfulBlocks";
import getResourceDataFromContentfulResource from "../../lib/getResourceDataFromContentfulResource";
import VaccineDevelopmentPhases from "../../components/VaccineDevelopmentPhases/VaccineDevelopmentPhases";
import InlineCard from "../../components/InlineCard/InlineCard";

// /////////////////////////////////////////////////////////////////////////////
// Outer Container Component:
// To Do
//   Move into its own and harden
// /////////////////////////////////////////////////////////////////////////////
// const SpaceWrapper = styled.div(props =>
//   mq({
//     paddingTop: props.theme.gutters.y.children,
//     paddingBottom: props.theme.gutters.y.children,
//   })
// )

const SpaceWrapper = ({ depth, children }) => {
  const theme = useTheme()
  const normalizedDepth = depth > 2 ? 2 : depth
  const depthChart = {
    0: {
      paddingTop: theme.gutters.y.root,
      paddingBottom: theme.gutters.y.root,
    },
    1: {
      paddingTop: theme.gutters.y.children,
      paddingBottom: theme.gutters.y.children,
    },
    2: {
      paddingTop: theme.gutters.y.siblings,
      paddingBottom: theme.gutters.y.siblings,
    },
  }

  return (
    <div css={mq({ ...depthChart[normalizedDepth] })} id="">
      {children}
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// Config:
// /////////////////////////////////////////////////////////////////////////////

const componentMap = (blocks, block, children, depth) => {
  const { component, data } = block
  const lowerComponent = component.toLowerCase()
  const index = uuidv4()
  const mapConfig = {
    AccForm: {
      variants: ['AccForm', 'Form'],
      return: data.containerStyle ? (
        <div css={{ ...data.containerStyle }}>
          <div css={{ display: 'flex', justifyContent: data.alignment, ...data.containerStyle }}>
            <AccForm key={index} formtype={data.type} thankyou={data.thankyou} onSubmitSuccess={data.onSubmitSuccess} SubmitText={data.SubmitText} />
          </div>
        </div>) : (
        <div css={{ display: 'flex', justifyContent: data.alignment, ...data.containerStyle }}>
          <AccForm key={index} formtype={data.type} thankyou={data.thankyou} onSubmitSuccess={data.onSubmitSuccess} SubmitText={data.SubmitText} />
        </div>)
    },
    AccDemoForm: {
      variants: ['AccDemoForm'],
      return: (
        <div css={{ display: 'flex', justifyContent: data.alignment }}>
          <AccDemoForm
            key={index + 1}
            formtype={data.type}
            thankyou={data.thankyou}
          />
        </div>
      ),
    },
    Accordion: {
      variants: ['Accordion'],
      return: <Accordion data={data}>{children}</Accordion>,
    },
    Accordions: {
      variants: ['Accordions'],
      return: (
        <SpaceWrapper key={index} depth={depth}>
          <Accordions data={data}>{children}</Accordions>
        </SpaceWrapper>
      ),
    },
    AONCampaignTabs: {
      variants: ['AONCampaignTabs', 'Tabs'],
      return: (
        <AONCampaignTabs />
      ),
    },
    VaccineDevelopmentPhases: {
      variants: ['VaccineDevelopmentPhases'],
      return: (
        <VaccineDevelopmentPhases />
      ),
    },
    InlineCard: {
      variants: ['InlineCard'],
      return: (
        <InlineCard data={data} />
      ),
    },
    AnimatedGIF: {
      variants: ['AnimatedGIF'],
      return: <AnimatedGIF data={data}>{children}</AnimatedGIF>,
    },
    BlogHeader: {
      variants: ['BlogHeader'],
      return: <BlogHeader data={data} />,
    },
    CallToAction: {
      variants: ['CallToAction', 'cta'],
      return: <CallToAction data={data}>{children}</CallToAction>,
    },
    CallToActions: {
      variants: ['CallToActions'],
      return: <CallToActions data={data}>{children}</CallToActions>,
    },
    Card: {
      variants: ['Card', 'Cards'],
      return: <Card data={data}>{children}</Card>,
    },
    FormRouter: {
      variants: ['FormRouter'],
      return: (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem 0 4rem 0',
          }}
        >
          <FormRouter>{children}</FormRouter>
        </div>
      ),
    },
    Grid: {
      variants: ['Grid'],
      return: <Grid data={data}>{children}</Grid>,
    },
    Hero: {
      variants: ['Hero'],
      return: <Hero data={data}>{children}</Hero>,
    },
    HeaderEvent: {
      variants: ['HeaderEvent', 'EventHeader'],
      return: <EventHeader data={data} />,
    },
    Image: {
      variants: ['Image'],
      return: <Image data={data}>{children}</Image>,
    },
    ImageOverlay: {
      variants: ['ImageOverlay'],
      return: <ImageOverlay data={data}>{children}</ImageOverlay>,
    },
    Indexer: {
      variants: ['Indexer'],
      return: <Indexer data={data} />,
    },
    Leader: {
      variants: ['Leader'],
      return: <Leader data={data}>{children}</Leader>,
    },
    List: {
      variants: ['List'],
      return: (
        <SpaceWrapper key={index} depth={depth}>
          <List data={data}>{children}</List>
        </SpaceWrapper>
      ),
    },
    ListItem: {
      variants: ['ListItem'],
      return: (
        <SpaceWrapper key={index} depth={depth}>
          <ListItem data={data}>{children}</ListItem>
        </SpaceWrapper>
      ),
    },
    Metric: {
      variants: ['Metric'],
      return: (
        <SpaceWrapper key={index} depth={depth}>
          <Metric data={data}>{children}</Metric>
        </SpaceWrapper>
      ),
    },
    NavTile: {
      variants: ['NavTile'],
      return: <NavTile data={data}>{children}</NavTile>,
    },
    PressNewsHeader: {
      variants: ['PressNewsHeader'],
      return: <PressNewsHeader data={data} />,
    },
    QuoteGrid: {
      variants: ['Quote Grid', 'QuoteGrid', 'quote grid'],
      return: <QuoteGrid data={data}>{children}</QuoteGrid>,
    },
    RaisedBox: {
      variants: ['RaisedBox', 'Raised Box'],
      return: (
        <SpaceWrapper key={index} depth={depth}>
          <RaisedBox data={data}>{children}</RaisedBox>
        </SpaceWrapper>
      ),
    },
    RichText: {
      variants: ['RichText', 'Rich Text'],
      return: data.containerStyle ? (
        <div css={{ ...data.containerStyle }}>
          <RichText data={data}>{children}</RichText>
        </div>
      ) : (
        <RichText data={data}>{children}</RichText>
      ),
    },
    Section: {
      variants: ['Section'],
      return: (
        <Section data={data} depth={depth}>
          {children}
        </Section>
      ),
    },
    SideBySide: {
      variants: ['SideBySide'],
      return: (
        <SpaceWrapper key={index} depth={depth}>
          <SideBySide data={data} depth={depth}>
            {children}
          </SideBySide>
        </SpaceWrapper>
      ),
    },
    Spacer: {
      variants: ['Spacer'],
      return: <Spacer data={data}>{children}</Spacer>,
    },
    Speaker: {
      variants: ['Speaker'],
      return: <Speaker data={data}>{children}</Speaker>,
    },
    Speakers: {
      variants: ['Speakers'],
      return: <Speakers data={data}>{children}</Speakers>,
    },
    Video: {
      variants: ['Video'],
      return: <Video data={data}>{children}</Video>,
    },
  }

  let mapFinal = {}
  Object.keys(mapConfig).forEach(key => {
    mapConfig[key].variants.forEach(variant => {
      const wrappedItem = (
        <SpaceWrapper key={index} depth={depth} wrap={false}>
          {mapConfig[key].return}
        </SpaceWrapper>
      )
      // mapFinal[variant.toLowerCase()] = wrappedItem
      mapFinal[variant.toLowerCase()] = mapConfig[key].return
    })
  })

  const result = mapFinal[lowerComponent]
  return result ? (
    result
  ) : (
    <div key={index}>
      <div>
        |{'-'.repeat(depth * 6)}> Placeholder: {component}
      </div>
      <div>{children}</div>
    </div>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// Recursive Flow:
// /////////////////////////////////////////////////////////////////////////////

const unBlocker = (block, depth, resource) => {
  const { blocks } = block
  // Identify & Execute Recursive Needs:
  let children = []
  if (blocks !== undefined && blocks.length !== 0) {
    children = blocks.map(block => unBlocker(block, depth + 1))
  }
  const result = componentMap(blocks, block, children, depth)
  return result
}

// ////////////////////////////////////////////////////////////////////////////
// Main Component
// ////////////////////////////////////////////////////////////////////////////

//
export const BlockBuster = ({ resource }) => {
  const { resourceId, collection } = resource

  const isFromContentful = Boolean(resource.from);

  const theme = useTheme()

  const dataToBlock = useDataTransform(resource)

  let blocks = resource.blocks;

  if (isFromContentful) {
    const transformedContentfulData = serializeContentfulBlocks(resource.blocks)

    blocks = transformedContentfulData.blocks;
  }

  const resourceData = isFromContentful ? getResourceDataFromContentfulResource(resource) : resource.data;

  /**  Patch Blog Data Duplication Issue -
   * From data migration, usually the blogs have duplicate data
   * within `data` and `blocks` [item 0]
   */
  if (
    !isFromContentful &&
    [
      'blogs',
      'blogsEngineering',
      'alias_blogs',
      'alias_blogsEngineering',
    ].includes(collection)
  ) {
    blocks[0].data.content.shift()
  }


  // Perform unshift if Hook validated and returned data
  dataToBlock && blocks.unshift(dataToBlock)
  // Recursively generate all root blocks of a resource
  const blocksUnBlocksUnBlocked = blocks.map(block => {
    const index = uuidv4()
    const ignoreGutter =
      block.component &&
      ['hero', 'list', 'section', 'aoncampaigntabs'].includes(block.component.toLowerCase())

    // Initiate recursive component generation for each root block
    const unBlockedBlocks = unBlocker(block, 0)

    return ignoreGutter ? (
      <div key={index} id="ignd">
        {unBlockedBlocks}
      </div>
    ) : (
      <div
        key={index}
        css={mq({
          // paddingTop: theme.gutters.y.root,
          // paddingBottom: theme.gutters.y.root,
          paddingLeft: theme.gutters.x.root,
          paddingRight: theme.gutters.x.root,
          a: { color: theme.colors.primary.actionBlue },
          ...resourceData.css,
        })}
        id="igndNO"
      >
        {unBlockedBlocks}
      </div>
    )
  })

  // Conditionally return wrapped or unwrapped blocks
  return ['resources', 'alias_resources'].includes(collection) ? (
    <Resources resourceId={resourceId} data={resourceData}>
      {blocksUnBlocksUnBlocked}
    </Resources>
  ) : (
    <div>{blocksUnBlocksUnBlocked}</div>
  )
}

export default BlockBuster
