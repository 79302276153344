import React, { useState, useEffect, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { mq, useLinkEval } from '../hooks'
import { useTheme } from 'emotion-theming'
import { Image } from '../components'
import { Display, TitleMedium, ButtonLink } from '../components/RichText'
import { Link as GatsbyLink } from 'gatsby'
import ModalVideo from '../components/ModalVideo/ModalVideo'
import PodcastButton from '../components/_inputs/PodcastButton/PodcastButton'

// ToDos:
// More Styling
// Modals and Such
// Build CTA Map Out

const ctaMap = {
  _default: {
    variants: ['_default'],
    return: 'Download now',
  },
  Webinar: {
    variants: ['Webinar'],
    return: 'Watch now',
  },
  Video: {
    variants: ['Video', 'Video Asset', 'Video URI'],
    return: 'Watch now',
  },
  SponsoredWebinar: {
    variants: ['SponsoredWebinar'],
    return: 'Register Now',
  },
  Podcast: {
    variants: ['Podcast', 'Audio Asset', 'Audio URI', 'InPagePodcast'],
    return: 'Listen Now',
  },
}
let mapFinal = {}
Object.keys(ctaMap).forEach((key) => {
  ctaMap[key].variants.forEach((variant) => {
    mapFinal[variant] = ctaMap[key].return
  })
})

export const Resources = ({ resourceId, data, children }) => {
  const theme = useTheme()

  const { heading, subHeading, thumbnail, resourceURI, resourceType } = data
  const [file, setFile] = useState({ audioFile: {} })

  useEffect(() => {
    setFile({
      audioFile: new Audio(resourceURI),
    })
  }, [])

  const start = () => {
    file.audioFile.play()
  }

  const pause = () => {
    file.audioFile.pause()
  }

  const linkType = useLinkEval(resourceURI)

  const ctaText = mapFinal[resourceType]
    ? mapFinal[resourceType]
    : mapFinal['_default']

  const button = useMemo(() => {
    if (!resourceURI) return null;

    return resourceType == 'InPagePodcast' || resourceType == 'Audio Asset' ? (
      <div>
        <PodcastButton clicked={start} text={ctaText} />
        <PodcastButton clicked={pause} text="pause" type="pause" />
      </div>
    ) : (
      <ButtonLink
        id={`acc-resources-${resourceType}`}
        link={resourceURI}
        newTab={true}
      >
        {ctaText}
      </ButtonLink>
    )
  }, [resourceType, ctaText, pause, resourceURI])

  const resourceThumbnail = useMemo(() => {
    const thumb = <Image data={{ path: thumbnail }} />

    if (!resourceURI) return thumb

    if (linkType === 'gatsby') {
      return (
        <GatsbyLink target="_blank" rel="noopener noreferrer" to={resourceURI}>
          {thumb}
        </GatsbyLink>
      )
    }

    if (linkType === 'video') {
      return <ModalVideo link={resourceURI}>{thumb}</ModalVideo>
    }

    return (
      <a target="_blank" rel="noopener noreferrer" href={resourceURI}>
        {thumb}
      </a>
    )
  }, [linkType, resourceURI])

  return (
    <div
      css={mq({
        a: { color: theme.colors.primary.actionBlue },
        img: { margin: 'auto' },
      })}
    >
      <div
        css={{
          borderBottom: '1px solid',
          margin: '2rem 3rem',
          textAlign: 'center',
        }}
      >
        <Display>{heading}</Display>
        <TitleMedium css={{ margin: '0rem 3rem 3rem 3rem' }}>
          {subHeading}
        </TitleMedium>
      </div>
      <div
        css={mq({
          display: 'flex',
          alignItems: ['center', 'center', 'center', 'flex-start'],
          justifyContent: 'space-around',
          // flexFlow: 'row wrap',
          flexDirection: [
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ],
        })}
      >
        <div css={{ flexBasis: '70%' }}>
          {children}
          {button && (
              <div
                  css={mq({ padding: theme.gutters.xOnly })}
                  className="resource-cta"
                  data-resource-id={resourceId}
              >
                {button}
              </div>
          )}
        </div>
        <div
          css={mq({
            flex: '1',
            margin: '1rem 2rem',
            opacity: '.7',
            width: ['60%', '55%', '35%', '100%'],
          })}
          className="resource-cta"
          data-resource-id={resourceId}
        >
          {resourceThumbnail}
        </div>
      </div>
    </div>
  )
}

export default Resources
