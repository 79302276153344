import React from 'react'
import { SEO } from './SEO'
import { theme } from '../../configs'
import { ThemeProvider } from 'emotion-theming'
import {
  Footer,
  Header,
} from '@konciergemd/first-responders-components'
import useScroll from '../../hooks/useScroll'
import { mq } from '../../hooks'

const getBgSchema = (children, scrollAtTop) => {
  if (scrollAtTop && children) {
    const heroBgColor =
      children[0].props.resource.blocks[0].data.backgroundColor
    return theme.backgrounds[heroBgColor.toLowerCase()]
  }
}

export const Layout = ({ children }) => {
  const scrollAtTop = useScroll()
  let seoTitle = 'Accolade'
  let metaDescription = 'Accolade Reinventing Healthcare'
  try {
    if (children[0].props.resource.title) {
      seoTitle = children[0].props.resource.title
    }
  } catch (e) {
    seoTitle = 'Accolade'
  }

  try {
    if (children[0].props.resource.description) {
      metaDescription = children[0].props.resource.description
    }
  } catch (e) {
    metaDescription = 'Accolade Reinventing Healthcare'
  }

  let bgSchema = theme.backgrounds['white']

  try {
    bgSchema = getBgSchema(children, scrollAtTop)
  } catch {
    // console.log('error getting BG SChea')
  }
  return (
    <>
      <SEO title={seoTitle} description={metaDescription} />
      <ThemeProvider theme={theme}>
        {typeof window !== 'undefined' && (
          <>
            <Header />
          </>
        )}
        <main css={mq({ overflow: 'hidden' })}>{children}</main>
        {typeof window !== 'undefined' && (
          <>
            <Footer />
          </>
        )}
      </ThemeProvider>
    </>
  )
}

export default Layout
