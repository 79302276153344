import React from 'react'
import styled from '@emotion/styled'
import { mq } from '../../hooks'
import ArrowForwardIcon from '../../../images/arrow-forward.svg'
import { Image } from '../Image'

const Link = styled.a`
  ${mq({
    color: '#1079BC',
    fontSize: '16px',
    img: { display: 'inline', marginLeft: '1rem' },
  })}
`

const Container = styled.div`
  ${mq({
    display: 'flex',
    overflow: 'hidden',

    flexDirection: ['column', 'column', 'row'],
    alignItems: 'center',
    margin: 'auto',
    maxWidth: ['311px', '311px', '1074px'],
    height: ['auto', 'auto', '198px'],
    background: 'white',

    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  })}
`

const TextContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: ['2rem 2rem', '2rem 2rem', '2rem 5rem'],
    justifyContent: 'space-between',
    flexShrink: 1,
  })}
`

const AuthorName = styled.div`
  ${mq({
    fontSize: '14px',
    color: '#565D64',
    marginBottom: '1rem',
  })}
`

const Heading = styled.div`
  ${mq({
    color: '#40464B',
    marginBottom: '1rem',
    fontSize: '1.5rem',
    lineHeight: '1.25',
  })}
`

export default function InlineCard({ data = {} }) {
  const { imagePath, author, heading, url, containerStyle } = data

  return (
    <Container css={mq(containerStyle || {})}>
      <Image
        data={{
          style: 'Standard',
          path: imagePath,
          containerStyle: { margin: 0, flexShrink: 0 },
          imgStyle: { width: '311px', height: '198px' },
        }}
      />
      <TextContainer>
        <div>
          <AuthorName>{author}</AuthorName>
          <Heading>{heading}</Heading>
        </div>
        <Link href={url} target="_blank">
          Read the full story
          <img src={ArrowForwardIcon} alt="Arrow Forward Icon" />
        </Link>
      </TextContainer>
    </Container>
  )
}
