/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import { AccForm } from './'
import { Select } from './components/FormStyles'
import { RichText } from '../components'
import { TitleSmall, TitleLarge } from '../components/RichText'
import { AccForms } from '../forms/FormConfig'

const eventMessage = [
  {
    type: 'TitleSmall',
    children: [
      {
        text:
          'Check out what we’ve been up to lately, and see what others are saying about our approach to the health and benefits experience. Send an email to ',
      },
      {
        type: 'link',
        url: 'mailto:media@accolade.com',
        children: [
          {
            text: 'media@accolade.com',
          },
        ],
      },
    ],
  },
]
const memMessage = [
  {
    type: 'TitleSmall',
    children: [
      {
        text: 'Please ',
      },
      {
        type: 'link',
        url: 'https://Member.Accolade.com',
        children: [
          {
            text: 'login here',
          },
        ],
      },
      {
        text: ' to access the member portal.',
      },
    ],
  },
  {
    type: 'TitleSmall',
    children: [
      {
        text:
          'If you have misplaced your Accolade Health Assistant’s direct number, please call us at (610) 834-2989 or visit ',
      },
      {
        type: 'link',
        url: 'https://Member.Accolade.com',
        children: [
          {
            text: 'Member.Accolade.com',
          },
        ],
      },
    ],
  },
]
const careersMessage = [
  {
    type: 'TitleSmall',
    children: [
      {
        text:
          'Join us in living our values, celebrating our differences, and continuing our commitment to inclusion. Send an email to ',
      },
      {
        type: 'link',
        url: 'mailto:recruiting@accolade.com',
        children: [
          {
            text: 'recruiting@accolade.com',
          },
        ],
      },
    ],
  },
]
const formMap = {
  Prospect: 'contactus',
  Solicitor: 'contactus',
  Partner: 'referral',
}
const inquiryTypes = [
  {
    inquiryType: 'default',
    option: 'Please select...',
    message: '',
  },
  {
    inquiryType: 'Member',
    option: 'I Am a Member, I Need Support',
    message: memMessage,
  },
  {
    inquiryType: 'Prospect',
    option: 'I Want Sales to Contact Me',
  },
  {
    inquiryType: 'Careers',
    option: 'Job / Recruitment Inquiry',
    message: careersMessage,
  },
  {
    inquiryType: 'Solicitor',
    option: 'I Have Something to Sell to Accolade',
  },
  {
    inquiryType: 'Media',
    option: 'Event / Media Inquiry',
    message: eventMessage,
  },
  {
    inquiryType: 'Partner',
    option: 'I want to partner with Accolade',
  }
]

export const FormRouter = () => {
  const [inquiryType, setInquiryType] = useState('Please select...')
  const contactThankYou = inquiryType === 'Partner' ? AccForms['referral'][0].thankYou : AccForms['contactus'][0].thankYou
  return (
    <>
      <TitleLarge> {`Select Inquiry Type`} </TitleLarge>
      <Select
        value={inquiryType}
        onChange={e => setInquiryType(e.target.value)}
      >
        {inquiryTypes.map((option, i) => {
          return i === 0 ? (
            <option key={i} defaultValue value={option.inquiryType}>
              {option.option}
            </option>
          ) : (
            <option key={i} value={option.inquiryType}>
              {option.option}
            </option>
          )
        })}
      </Select>
      <br />
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem',
        }}
      >
        {inquiryType === 'Prospect' || inquiryType === 'Solicitor' || inquiryType === 'Partner' ? (
          <AccForm
            formtype={formMap[inquiryType]}
            thankyou={contactThankYou}
            inquiryType={inquiryType}
          ></AccForm>
        ) : (
          inquiryTypes.map((inq, i) =>
            inq.inquiryType === inquiryType ? (
              <div key={i}
                css={{
                  padding: '1rem',
                }}
              >
                <RichText
                  data={{
                    content: inq.message,
                  }}
                />
              </div>
            ) : null
          )
        )}
      </div>
    </>
  )
}

export default FormRouter
