import React, { useEffect } from 'react'
import { Layout, BlockBuster } from './index'

export const Pages = props => {
  const { pageContext: resource } = props

  useEffect(() => {
    window.dataLayer.push({ 'event': 'optimize.activate' });
  }, []);

  return (
    <Layout>
      <BlockBuster resource={resource} />
      <div id="resource-data" data-resource-id={resource.id}></div>
    </Layout>
  )
}

export default Pages
